import React from "react";
import DashboardE from "./DashboardE";
import DashboardP from "./DashboardP";

import { connect } from "react-redux";

function Dashboard({ darkTheme, lang }) {
  const localTheme = JSON.parse(localStorage.getItem("darkTheme"));
  const localLang = JSON.parse(localStorage.getItem("lang"));
  // const [handleOpen, setHandleOpen] = useState({ open: false });

  let isTheme = darkTheme;
  let isEng = lang;

  if (!lang) {
    isEng = localLang;
  }

  if (!darkTheme) {
    isTheme = localTheme;
  }

  

  return (
    <>
    {isEng? <DashboardP/> : <DashboardE/>}
    </>
   
  );
}

const mapStateToProps = (state) => {
  return {
    darkTheme: state.ui.darkTheme,
    lang: state.ui.lang,
  };
};

export default connect(mapStateToProps)(Dashboard);
