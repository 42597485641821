import React, { useState, Fragment } from "react";

import { makeStyles } from "@material-ui/core/styles";
import { GmailTabs, GmailTabItem } from "@mui-treasury/components/tabs/gmail";
import { Box, Paper } from "@material-ui/core";
import { Inbox, LocalOffer, People, Info } from "@material-ui/icons";
import Typography from "@material-ui/core/Typography";
import { Link } from "react-router-dom";
// import Overview from "./Overview";
import Curriculum from "./Curriculum";

const useStyles = makeStyles((theme) => ({
  gmailTabs: {
    backgroundColor: "inherit",
  },
  wrapper: {
    color: "darkgray !important",
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
}));

function TabPanel({ children, tabNum, index, ...other }) {
  return (
    <div
      role="tabpanel"
      hidden={tabNum !== index}
      id={`scrollable-force-tabpanel-${index}`}
      aria-labelledby={`scrollable-force-tab-${index}`}
      {...other}
    >
      {tabNum === index && <Box p={3}>{children}</Box>}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `scrollable-force-tab-${index}`,
    "aria-controls": `scrollable-force-tabpanel-${index}`,
  };
}

const CourseTabs = ({course}) => {
  const classes = useStyles();
  const [tabNum, setTabNum] = useState(0);

  const handleChange = (_, newValue) => {
    setTabNum(newValue);
  };


  const Books = () => {
    // console.log("book:", course.books)
    // if(course.books) 
    return(
      <>
      {/* <p>Books</p> */}
      {course.books && course.books.map((book) => (
          <Link to={`/book/${book.id}`} className={classes.secondaryHeading}>
            <Typography key={book.id}>
          {book.title}
        </Typography>
        </Link>
      
      ))}
      </>
      
    )
    // return null;
  }
  // console.log("book:", course.course.books)
  const Sims = () => {
    console.log("book:", course)
    return(
      <>
      {course.sims && course.sims.map((book) => (
          <Link to={`/products/${book._id}`}>
            <div key={book.id}>
          {book.title}
        </div>
        </Link>
      
      ))}
      </>
      
    )
    // return null;
  }
  return (
    <Fragment>
      <GmailTabs
        value={tabNum}
        onChange={handleChange}
        variant="scrollable"
        scrollButtons="on"
        aria-label="scrollable force tabs"
        className={classes.gmailTabs}
      >
        <GmailTabItem
          icon={<Inbox />}
          label={"Smart Books"}
          {...a11yProps(0)}
          classes={{ wrapper: classes.wrapper }}
        />
        <GmailTabItem
          icon={<People />}
          label={"Videos"}
          {...a11yProps(1)}
          classes={{ wrapper: classes.wrapper }}
        />
        <GmailTabItem
          icon={<LocalOffer />}
          label={"VLabs & Sims"}
          {...a11yProps(2)}
          classes={{ wrapper: classes.wrapper }}
        />
        <GmailTabItem
          icon={<Info />}
          label={"Exam"}
          {...a11yProps(3)}
          classes={{ wrapper: classes.wrapper }}
        />
        <GmailTabItem
          icon={<Info />}
          label={"Resources"}
          {...a11yProps(4)}
          classes={{ wrapper: classes.wrapper }}
        />
      </GmailTabs>

      <Box ml={4}>
        <Paper elevation={0}>
          <TabPanel tabNum={tabNum} index={0}>
            {/* <Overview /> */}
            {course && <Books />}
          </TabPanel>

          <TabPanel tabNum={tabNum} index={1}>
            <Curriculum course={course} />
            
          </TabPanel>

          <TabPanel tabNum={tabNum} index={2}>
            {/* FAQ */}
            {course && <Sims />}
          </TabPanel>

          <TabPanel tabNum={tabNum} index={3}>
            Announcement
          </TabPanel>

          <TabPanel tabNum={tabNum} index={4}>
            Reviews
          </TabPanel>
        </Paper>
      </Box>
    </Fragment>
  );
};

export default CourseTabs;
