import * as actionTypes from "./actionTypes";
import axios from "../../axios";


// ----------------- Course Index ------------------ //
export const fetchCourseIndexStart = () => {
  return {
    type: actionTypes.FETCH_COURSE_INDEX_START,
  };
};

export const fetchCourseIndexSuccess = (courseIndex) => {
  return {
    type: actionTypes.FETCH_COURSE_INDEX_SUCCESS,
    courseIndex: courseIndex,
  };
};

export const fetchCourseIndexFail = (error) => {
  return {
    type: actionTypes.FETCH_COURSE_INDEX_FAIL,
    error: error,
  };
};

export const fetchCourseIndex = (init) => {
  return (dispatch) => {
    dispatch(fetchCourseIndexStart());

   

    // dispatch(fetchCourseIndexSuccess(course_index));
    // if (init) {
    //   dispatch(fetchCourses(course_index[0].maDanhMuc));
    // }
    
    axios
      // .get("https://elearning0706.cybersoft.edu.vn/api/QuanLyKhoaHoc/LayDanhMucKhoaHoc")
      .get("/course/cat")
      .then((response) => {
        dispatch(fetchCourseIndexSuccess(response.data));
        // console.log("couse index:", response.data)
        if (init) {
          dispatch(fetchCourses(response.data[0].id));
        }
      })
      .catch((error) => {
        dispatch(fetchCourseIndexFail(error));
      });
  };
};

// ----------------- Courses List ------------------ //
export const fetchCoursesStart = () => {
  return {
    type: actionTypes.FETCH_COURSES_START,
  };
};

export const fetchCoursesSuccess = (courseList) => {
  return {
    type: actionTypes.FETCH_COURSES_SUCCESS,
    courseList: courseList,
  };
};

export const fetchCoursesFail = (error) => {
  return {
    type: actionTypes.FETCH_COURSES_FAIL,
    error: error,
  };
};

export const fetchCourses = (courseType, group, keyWord) => {
  // console.log("courseType, group, keyWord", courseType, group, keyWord)
  
  return (dispatch) => {
    dispatch(fetchCoursesStart());
    if (group === undefined) {
      group = "1";
    }
    // let url = `https://elearning0706.cybersoft.edu.vn/api/QuanLyKhoaHoc/LayKhoaHocTheoDanhMuc?maDanhMuc=${courseType}&MaNhom=${group}`;
    let url = `/course`;
    if (courseType === "all") {
      // url = `http://localhost:2094/course/group?group=${group}`;
      // url = `http://localhost:3000/allcourses`;
      // let url = `/sib`;
    }
    if (keyWord) {
      // url = `http://localhost:2094/course/search?keyWord=${keyWord}&group=${group}`;
      // url = `http://localhost:3000/allcourses`;
      // let url = `/sib`;
    }

    // console.log("urj", url)
    // dispatch(fetchCoursesSuccess(allcourses));

    axios
      .get(url)
      .then((response) => {
        // console.log("Courses List: ", response.data);
        dispatch(fetchCoursesSuccess(response.data));
      })
      .catch((error) => {
        dispatch(fetchCoursesFail(error));
      });
  };
};

// ----------------- Course Detail ------------------ //
export const fetchCourseDetailStart = () => {
  return {
    type: actionTypes.FETCH_COURSE_DETAIL_START,
  };
};

export const fetchCourseDetailSuccess = (courseDetail) => {
  return {
    type: actionTypes.FETCH_COURSE_DETAIL_SUCCESS,
    courseDetail: courseDetail,
  };
};

export const fetchCourseDetailFail = (error) => {
  return {
    type: actionTypes.FETCH_COURSE_DETAIL_FAIL,
    error: error,
  };
};

export const fetchCourseDetail = (courseId) => {
  // console.log("courseId",courseId)
  return (dispatch) => {
    dispatch(fetchCourseDetailStart());


    // dispatch(fetchCourseDetailSuccess(allcourses[courseId]));


    axios
      .get(`/course/${courseId}`)
      // .get(`https://api.daneshpark.org/sib/10101`)
      .then((response) => {
        dispatch(fetchCourseDetailSuccess(response.data));
      })
      .catch((error) => {
        dispatch(fetchCourseDetailFail(error));
      });
  };
};

// ----------------- User Detail ------------------ //
export const fetchUserDetailStart = () => {
  return {
    type: actionTypes.FETCH_USER_DETAIL_FAIL,
  };
};

export const fetchUserDetailSuccess = (userDetail) => {
  return {
    type: actionTypes.FETCH_USER_DETAIL_SUCCESS,
    userDetail: userDetail,
  };
};

export const fetchUserDetailFail = (error) => {
  return {
    type: actionTypes.FETCH_USER_DETAIL_FAIL,
    error: error,
  };
};

export const fetchUserDetail = () => {
  return (dispatch) => {
    dispatch(fetchUserDetailStart());
    const user = JSON.parse(localStorage.getItem("user"));
    // const url = "/QuanLyNguoiDung/ThongTinTaiKhoan";
    const url = "https://elearning0706.cybersoft.edu.vn/api/QuanLyNguoiDung/ThongTinTaiKhoan";
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${user.accessToken}`,
    };
    const data = {
      taiKhoan: user.taiKhoan,
    };


    // const userdetail = 
    //   {
    //     "courses":[
    //       {
    //         id: 4,
    //         title: "Modern Physics for Kids",
    //         titleP: "فیزیک نوین برای کودکان",
    //       },
    //       {
    //         id: 5,
    //         title: "Basics and Applications of Lasers",
    //         titleP: "مفاهیم پایه و کاربردهای لیزر",
    //       },
    //       {
    //         id: 6,
    //         title: "Introduction to Nanotechnology",
    //         titleP: "آشنایی با فناوری نانو",            
    //       },
    //     ],
    //     "chiTietKhoaHocGhiDanh": [
    //       {
    //         "maKhoaHoc": "backendf11",
    //         "tenKhoaHoc": "Advanced-react-and-redux-2018-edition"
    //       },
    //       {
    //         "maKhoaHoc": "backendfrc",
    //         "tenKhoaHoc": "Advandge-programming"
    //       },
    //       {
    //         "maKhoaHoc": "design-004",
    //         "tenKhoaHoc": "Workflow of Modern Web Design from Wireframes to Style Guide"
    //       },
    //       {
    //         "maKhoaHoc": "python02",
    //         "tenKhoaHoc": "The Python Mega Course: Build 10 Real World Applications"
    //       }
    //     ],
    //     "taiKhoan": "thichmidu",
    //     "matKhau": "thichmidu",
    //     "hoTen": "thichmidu",
    //     "soDT": "+3578745632",
    //     "maLoaiNguoiDung": "HV",
    //     "maNhom": "GP08",
    //     "email": "thichmidu@gmail.com"
    //   }
    // ;
    // dispatch(fetchUserDetailSuccess(userdetail));

    axios
    // .get(`/course/user-courses/${user}`)
    .get(`/course/user-courses/`)
    // .get(`https://api.daneshpark.org/sib/10101`)
    .then((response) => {
      dispatch(fetchUserDetailSuccess(response.data));
    })
    .catch((error) => {
      dispatch(fetchUserDetailFail(error));
    });
    // console.log("userDetail:", userdetail.chiTietKhoaHocGhiDanh)
    // axios({ method: "post", url, headers, data })
    // // axios.get("http://localhost:3000/userdetail")
    //   .then((response) => {
    //     console.log("User Detail: ", response.data);
    //     dispatch(fetchUserDetailSuccess(response.data));
    //   })
    //   .catch((error) => {
    //     dispatch(fetchUserDetailFail(error));
    //   });
  };
};
