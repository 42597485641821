import React, { useEffect, useState } from "react";
import { Box, Typography } from "@material-ui/core";
import InnerHTML from "dangerously-set-html-content";

import { connect } from "react-redux";
import axios from "../../axios";

const Help = ({ darkTheme, lang }) => {
  const localTheme = JSON.parse(localStorage.getItem("darkTheme"));
  const localLang = JSON.parse(localStorage.getItem("lang"));
  const [help, setHelp] =useState({
    helpE : "<h1>Please wait</h1>",
    helpP : "<h1>لطفا منتظر بمانید</h1>",


  })


  let isTheme = darkTheme;
  let isEng = lang;

  if (!lang) {
    isEng = localLang;
  }

  if (!darkTheme) {
    isTheme = localTheme;
  }

  // console.log("Help:", help);

  const getHelp = (id)=>{
    axios
    // .get(`https://api.daneshpark.org/site/help`)
    .get(`/site/help`)
    .then((response) => {
      setHelp(response.data.data) 
    })
    .catch((error) => {
      console.log("Error: ", error);
      // dispatch(fetchCoursesFail(error));
    });
  }

  useEffect(() => {
       getHelp();
  }, []);



  const HelpE = () => {
    return (
      <>
      <Box my={5} style={{ minHeight: 520 }}>
          <Box mx={6} py={3}>
            <Typography variant="h5" gutterBottom>
              <strong>Smart Interactive Books</strong>
            </Typography>
            <Typography variant="subtitle1" gutterBottom>
              Interactive videos, Smart tests, ...
            </Typography>
          </Box>
          {/* <CourseList /> */}
          <InnerHTML html={help.helpE} />
        </Box>
  
      </>
      )    
  };

  const HelpP = () => {
    return (
      <>
      <Box my={5} style={{ minHeight: 520 }}>
          <Box mx={6} py={3}>
            <Typography variant="h5" gutterBottom>
              <strong>کتاب‌های تعاملی هوشمند</strong>
            </Typography>
            <Typography variant="subtitle1" gutterBottom>
              شامل ویدئوی تعاملی، آزمون هوشمند، ...
            </Typography>
          </Box>
          {/* <CourseList /> */}
          <InnerHTML html={help.helpP} />
        </Box>
  
      </>
      )  
  };

  return <Box>{isEng ? <HelpP /> : <HelpE />}</Box>;
};

const mapStateToProps = (state) => {
  return {
    darkTheme: state.ui.darkTheme,
    lang: state.ui.lang,
  };
};

export default connect(mapStateToProps)(Help);
