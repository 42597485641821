import React, { useState, useEffect } from "react";
import { connect } from "react-redux";

import { Grid, Box } from "@material-ui/core";
import { GmailTabs, GmailTabItem } from "@mui-treasury/components/tabs/gmail";
import { makeStyles } from "@material-ui/core/styles";

// import CourseCarousel from "../../components/CourseList/CourseCarousel/CourseCarousel";
import CourseCarousel from "./CourseCarousel";

import * as actions from "../../store/actions";
import SkeletonCard from "./SkeletonCard";
import axios from "../../axios";

const useStyles = makeStyles((theme) => ({
  gmailTabs: {
    backgroundColor: "inherit",
  },
  wrapper: {
    color: "darkgray !important",
  },
}));

function TabPanel({ children, tabNum, index, ...other }) {
  return (
    <div
      role="tabpanel"
      hidden={tabNum !== index}
      id={`scrollable-force-tabpanel-${index}`}
      aria-labelledby={`scrollable-force-tab-${index}`}
      {...other}
    >
      {tabNum === index && <Box p={3}>{children}</Box>}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `scrollable-force-tab-${index}`,
    "aria-controls": `scrollable-force-tabpanel-${index}`,
  };
}

function CourseList(props) {
  const classes = useStyles();
  const [tabNum, setTabNum] = useState(0);
  // const {  courseList, loading } = props;
  // const { onfetchCourseIndex, onfetchCourses } = props;
  const [loading, setLoading] = useState(true);
  // const {books} = props;
  const [books, setBooks] =useState(null)
  const [courseIndex, setCourseIndex] = useState();
  const [courseList, setCourseList] = useState( )

  // useEffect(() => {
  //   onfetchCourseIndex(true);
  // }, [onfetchCourseIndex]);
  
  const getBooks = ()=>{
    axios
    .get(`https://api.daneshpark.org/sib/`)
    .then((response) => {
      setBooks(response.data) 
    })
    .catch((error) => {
      console.log("Error: ", error);
      // dispatch(fetchCoursesFail(error));
    });
  }

  useEffect(() => {
    getBooks();
}, []);

  useEffect(() => {
    // onfetchCourseIndex(true);
    if(books) {
      setCourseIndex(books.category)
      let books_cat = [];
    // let found=flase;
    for (let i = 0; i < books.books.length; ++i) {
      if (
        books.books[i].cat.includes(books.category[0].id)  )
        books_cat.push(books.books[i]);
    }
    setCourseList(books_cat)
    // console.log("courseIndex:", books_cat)
      setLoading(false)
      // console.log("courseIndex:", books.category)
    }   
  }, [books]);

  // if (!books?.length && !loading) return "No posts";

  const handleChange = (_, newValue) => {
    
    setTabNum(newValue);
    // onfetchCourses(courseIndex[newValue].maDanhMuc);
    let books_cat = [];
    // let found=flase;
    for (let i = 0; i < books.books.length; ++i) {
      if (
        books.books[i].cat.includes(courseIndex[newValue].id)  )
        books_cat.push(books.books[i]);
    }
    setCourseList(books_cat)
    // console.log("courseIndex:", books_cat)
  };

  let courseRender = <SkeletonCard />;

  if (!loading) {
    courseRender = courseIndex.map((tab, index) => (
      <TabPanel tabNum={tabNum} index={index} key={tab.maDanhMuc}>
        <Grid container justify="center" spacing={2}>
          <CourseCarousel courseList={courseList} />
        </Grid>
      </TabPanel>
    ));
  }

  return (
    <>
    {!loading && 
     <Grid container justify="center">
     <GmailTabs
       value={tabNum}
       onChange={handleChange}
       variant="scrollable"
       scrollButtons="on"
       aria-label="scrollable force tabs"
       className={classes.gmailTabs}
     >
       {courseIndex.map((tab, index) => (
         <GmailTabItem
           key={tab.title}
           label={tab.title}
           classes={{ wrapper: classes.wrapper }}
           {...a11yProps(index)}
         />
       ))}
     </GmailTabs>
     {courseRender}
   </Grid>}
     
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    courseIndex: state.courses.courseIndex,
    courseList: state.courses.courseList,
    loading: state.courses.loading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onfetchCourseIndex: (init) => dispatch(actions.fetchCourseIndex(init)),
    onfetchCourses: (courseIndex) =>
      dispatch(actions.fetchCourses(courseIndex)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CourseList);
