import axios from "../../axios";
import * as actionTypes from "./actionTypes";
// import { actionTypes } from "../contants/action-types";

export const fetchProducts = () => async (dispatch) => {
  // const response = await axios.get("https://fakestoreapi.com/products");
  const response = await axios.get("/sims/all");
  dispatch({ type: actionTypes.FETCH_PRODUCTS, payload: response.data });
};

export const fetchProduct = (id) => async (dispatch) => {
  // const response = await axios.get(`https://fakestoreapi.com/products/${id}`);
  const response = await axios.get(`/sims/${id}`);
  dispatch({ type: actionTypes.SELECTED_PRODUCT, payload: response.data });
};

export const setProducts = (products) => {
  return {
    type: actionTypes.SET_PRODUCTS,
    payload: products,
  };
};

export const selectedProduct = (product) => {
  return {
    type: actionTypes.SELECTED_PRODUCT,
    payload: product,
  };
};

export const removeSelectedProduct = () => {
  return {
    type: actionTypes.REMOVE_SELECTED_PRODUCT,
  };
};


// ----------------- Product Category ------------------ //
export const fetchProductCatStart = () => {
  return {
    type: actionTypes.FETCH_PRODUCT_CAT_START,
  };
};

export const fetchProductCatSuccess = (productCat) => {
  return {
    type: actionTypes.FETCH_PRODUCT_CAT_SUCCESS,
    productCat: productCat,
  };
};

export const fetchProductCatFail = (error) => {
  return {
    type: actionTypes.FETCH_PRODUCT_CAT_FAIL,
    error: error,
  };
};

export const fetchProductCat = (init) => {
  return (dispatch) => {
    dispatch(fetchProductCatStart());
    axios
      .get("/QuanLyKhoaHoc/LayDanhMucKhoaHoc")
      // .get("http://localhost:3000/product_cat")
      .then((response) => {
        dispatch(fetchProductCatSuccess(response.data));
        console.log("product cat:", response.data)
        // if (init) {
        //   dispatch(fetchCourses(response.data[0].maDanhMuc));
        // }
      })
      .catch((error) => {
        dispatch(fetchProductCatFail(error));
      });
  };
};