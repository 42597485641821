import React from "react";
import { Box, Typography } from "@material-ui/core";
// import axios from "../../axios";

import { connect } from "react-redux";

import CourseList from "./CourseList";

const Sbooks = ({ darkTheme, lang }) => {
  const localTheme = JSON.parse(localStorage.getItem("darkTheme"));
  const localLang = JSON.parse(localStorage.getItem("lang"));
  // const [books, setBooks] =useState(null)

  let isTheme = darkTheme;
  let isEng = lang;

  if (!lang) {
    isEng = localLang;
  }

  if (!darkTheme) {
    isTheme = localTheme;
  }

  // console.log("Books:", books);
//   const getBooks = ()=>{
//     axios
//     .get(`https://api.daneshpark.org/sib/`)
//     .then((response) => {
//       setBooks(response.data) 
//     })
//     .catch((error) => {
//       console.log("Error: ", error);
//       // dispatch(fetchCoursesFail(error));
//     });
//   }

//   useEffect(() => {
//     getBooks();
// }, []);

  const SbooksE = () => {
    return (
    <>
    <Box my={5} style={{ minHeight: 520 }}>
        <Box mx={6} py={3}>
          <Typography variant="h5" gutterBottom>
            <strong>Smart Interactive Books</strong>
          </Typography>
          <Typography variant="subtitle1" gutterBottom>
            Interactive videos, Smart tests, ...
          </Typography>
        </Box>
        <CourseList />
      </Box>

    </>
    )    
  };

  const SbooksP = () => {
    return (
      <>
      <Box my={5} style={{ minHeight: 520 }}>
          <Box mx={6} py={3}>
            <Typography variant="h5" gutterBottom>
              <strong>کتاب‌های تعاملی هوشمند</strong>
            </Typography>
            <Typography variant="subtitle1" gutterBottom>
              شامل ویدئوی تعاملی، آزمون هوشمند، ...
            </Typography>
          </Box>
          <CourseList />
        </Box>
  
      </>
      )  
  };

  return <Box>{isEng ? <SbooksP /> : <SbooksE />}</Box>;
};

const mapStateToProps = (state) => {
  return {
    darkTheme: state.ui.darkTheme,
    lang: state.ui.lang,
  };
};

export default connect(mapStateToProps)(Sbooks);