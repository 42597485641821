import React, { useEffect, useState } from "react";
import { Box, Typography, Button } from "@material-ui/core";
import InnerHTML from "dangerously-set-html-content";
import { Link } from "react-router-dom";
import { withRouter } from "react-router-dom";
import { makeStyles, useTheme } from "@material-ui/core/styles";

import { connect } from "react-redux";
import axios from "../../../../axios";

import FormE from './Form/FormE'
import FormP from './Form/FormP'

const useStyles = makeStyles((theme) => ({
  position: {
    marginTop: "5%",
    [theme.breakpoints.up("md")]: {
      position: "fixed",
      marginTop: "8%",
      marginLeft: "5%",
    },
  },
  chipBox: {
    display: "flex",
    flexWrap: "wrap",
    "& > *": {
      margin: theme.spacing(0.5),
    },
  },

  root_chap: {
    maxWidth: 310,
    minWidth: 270,
    margin: "auto",
  },
  content: {
    padding: 24,
  },
  avatar: {
    width: 50,
    height: 50,
    border: "2px solid #fff",
    margin: "-48px 32px 0 auto",
    "& > img": {
      margin: 0,
    },
  },
  button: {
    display: "block",
    width: "100%",
    height: "3rem",
    border: "none",
    background: "linear-gradient(120deg, #2980b9, #8e44ad, #2980b9)",
    backgroundSize: "200%",
    color: "#fff",
    outline: "none",
    transition: "0.5s",
    cursor: "pointer",

    "&:hover": {
      backgroundPosition: "right",
    },
  },
}));

const VideoPage = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const [video, setVideo] = useState(null);
  const { courseDetail, darkTheme, lang, match } = props;

  const localTheme = JSON.parse(localStorage.getItem("darkTheme"));
  const localLang = JSON.parse(localStorage.getItem("lang"));

  let isTheme = darkTheme;
  let isEng = lang;

  if (!lang) {
    isEng = localLang;
  }

  if (!darkTheme) {
    isTheme = localTheme;
  }

  // console.log("courseDetail:", courseDetail);

  const getVideo = (id) => {
    axios
      .get(`/video/${id}`)
      .then((response) => {
        setVideo(response.data);
      })
      .catch((error) => {
        console.log("Error: ", error);
        // dispatch(fetchCoursesFail(error));
      });
  };

  useEffect(() => {
    getVideo(match.params.id);
  }, [match.params.id]);

  const VideoE = () => {
    // console.log("video", video)
    return (
      <>    
       <Box my={2} >
          <Box mx={6} py={1}>
            <Typography variant="h5" >
              <strong>{video?.title}</strong>
            </Typography>
            {/* <Typography variant="subtitle1" gutterBottom>
              Interactive videos, Smart tests, ...
            </Typography> */}
          </Box>
          {/* {video?.title} */}
          {/* <CourseList /> */}
          {/* <InnerHTML html={help.VideoE} /> */}
        </Box>
        <div >
        {/* <InnerHTML html={`<style>.r1_iframe_embed {position: relative; overflow: hidden; width: 100%; height: auto; padding-top: 56.25%; } .r1_iframe_embed iframe { position: absolute; top: 0; left: 0; width: 100%; height: 100%; border: 0; }</style><div class="r1_iframe_embed"><iframe src="https://player.arvancloud.com/index.html?config=https://daneshpark.arvanvod.com/p32LrpZ16y/Mg4ZvGmklW/origin_config.json" style="border:0 #ffffff none;" name="Installingsoftware2" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen="true" webkitallowfullscreen="true" mozallowfullscreen="true"></iframe></div>`} /> */}
        <InnerHTML html={video?.iframe} />
      </div>
     

        <Box
          component={Link}
          to={`/course/${courseDetail?.course?.id}`}
          style={{ textDecoration: "none" }}
        >
          <Button size="small" className={classes.button}>
            Back to Course
          </Button>
        </Box>

              
        <Box mx={6} py={3}>
        <Typography variant="h5" gutterBottom>
              <strong>How much did you learn from this video in the following fields:</strong>
            </Typography>
            <FormE />
          </Box>
      </>
    );
  };

  const VideoP = () => {
    return (
      <>
        <Box my={5} >
          <Box mx={6} py={3}>
            <Typography variant="h5" gutterBottom>
              <strong>{video?.titleP}</strong>
            </Typography>
            {/* <Typography variant="subtitle1" gutterBottom>
              شامل ویدئوی تعاملی، آزمون هوشمند، ...
            </Typography> */}
          </Box>
          {/* <CourseList /> */}
          {/* <InnerHTML html={help.VideoP} /> */}
        </Box>

        <div >
        {/* <InnerHTML html={`<style>.r1_iframe_embed {position: relative; overflow: hidden; width: 100%; height: auto; padding-top: 56.25%; } .r1_iframe_embed iframe { position: absolute; top: 0; left: 0; width: 100%; height: 100%; border: 0; }</style><div class="r1_iframe_embed"><iframe src="https://player.arvancloud.com/index.html?config=https://daneshpark.arvanvod.com/p32LrpZ16y/Mg4ZvGmklW/origin_config.json" style="border:0 #ffffff none;" name="Installingsoftware2" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen="true" webkitallowfullscreen="true" mozallowfullscreen="true"></iframe></div>`} /> */}
        <InnerHTML html={video?.iframe} />
      </div>

        <Box
          component={Link}
          to={`/course/${courseDetail?.course?.id}`}
          style={{ textDecoration: "none" }}
        >
          <Button size="small" className={classes.button}>
          <Typography variant="h6" gutterBottom>
              <strong>بازگشت به دوره</strong>
            </Typography>
            
          </Button>
        </Box>

        

        <Box mx={6} py={3}>
        <Typography variant="h5" gutterBottom>
              <strong>این ویدئو در افزایش دانش شما در هر یک از شاخه‌های زیر چقدر کمک کرد؟</strong>
            </Typography>
            <FormE />
          </Box>
      </>
    );
  };

  return <Box>{isEng ? <VideoP /> : <VideoE />}</Box>;
};

const mapStateToProps = (state) => {
  return {
    courseDetail: state.courses.courseDetail,
    darkTheme: state.ui.darkTheme,
    lang: state.ui.lang,
  };
};

export default connect(mapStateToProps)(withRouter(VideoPage));
