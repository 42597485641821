import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Link } from "react-router-dom";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import InnerHTML from "dangerously-set-html-content";

import { Box, Grid, Typography, useMediaQuery } from "@material-ui/core";
// import { Avatar, Tooltip, Chip } from "@material-ui/core";
// import AvatarGroup from "@material-ui/lab/AvatarGroup";

// import Switch from "@material-ui/core/Switch";
// import { useN01SwitchStyles } from "@mui-treasury/styles/switch/n01";

// import PersonAddIcon from "@material-ui/icons/PersonAdd";

// import HoverRating from "../../../Rating/Rating";
// import CourseTabs from "./CourseTabs/CourseTabs";
// import ShowcaseCard from "../../CourseCard/ShowcaseCard";

import * as actions from "../../../../store/actions";
// import Skeleton from "@material-ui/lab/Skeleton";

import axios from "../../../../axios";



import cx from "clsx";


import { useSnackbar } from "notistack";


import { Card, CardMedia } from "@material-ui/core";
import { Button } from "@material-ui/core";
import { List, ListItem, ListItemIcon, ListItemText } from "@material-ui/core";

import FindInPageIcon from "@material-ui/icons/FindInPage";
import AllInclusiveIcon from "@material-ui/icons/AllInclusive";
import PhoneIphoneIcon from "@material-ui/icons/PhoneIphone";
import VerifiedUserIcon from "@material-ui/icons/VerifiedUser";
import BuildIcon from "@material-ui/icons/Build";

import { useSoftRiseShadowStyles } from "@mui-treasury/styles/shadow/softRise";
import { useSlopeCardMediaStyles } from "@mui-treasury/styles/cardMedia/slope";


const useStyles = makeStyles((theme) => ({
  position: {
    marginTop: "5%",
    [theme.breakpoints.up("md")]: {
      position: "fixed",
      marginTop: "8%",
      marginLeft: "5%",
    },
  },
  chipBox: {
    display: "flex",
    flexWrap: "wrap",
    "& > *": {
      margin: theme.spacing(0.5),
    },
  },


  root_chap: {
    maxWidth: 310,
    minWidth: 270,
    margin: "auto",
  },
  content: {
    padding: 24,
  },
  avatar: {
    width: 50,
    height: 50,
    border: "2px solid #fff",
    margin: "-48px 32px 0 auto",
    "& > img": {
      margin: 0,
    },
  },
  button: {
    display: "block",
    width: "100%",
    height: "3rem",
    border: "none",
    background: "linear-gradient(120deg, #2980b9, #8e44ad, #2980b9)",
    backgroundSize: "200%",
    color: "#fff",
    outline: "none",
    transition: "0.5s",
    cursor: "pointer",

    "&:hover": {
      backgroundPosition: "right",
    },
  },


}));

function CourseDetail(props) {
  const classes = useStyles();
  const theme = useTheme();

  const [book, setBook] =useState(null)
  const [content, setContent] = useState(0);



  const matchMD = useMediaQuery(theme.breakpoints.up("md"));
  // const myInfo = JSON.parse(localStorage.getItem("user"));
  const { courseDetail, loading, match, userList } = props;
  const { onFetchCourseDetail, onFetchUserListInThisCourse } = props;

  // const switchStyles = useN01SwitchStyles();
  const [onShow, setOnShow] = useState(false);


  const getBook = (id)=>{
    axios
    .get(`/sib/${id}`)
    .then((response) => {
      setBook(response.data) 
    })
    .catch((error) => {
      console.log("Error: ", error);
      // dispatch(fetchCoursesFail(error));
    });
  }

  useEffect(() => {
       getBook(match.params.id);
  }, [match.params.id]);

  const changeContent = (n) => {
    setContent(n);
  };

  let isMe = false;


  const ShowcaseCard = (props) => {
    const cardStyles = useStyles();
    const mediaStyles = useSlopeCardMediaStyles();
    const shadowStyles = useSoftRiseShadowStyles();
  
    const { isMe, error, success } = props;
    const { imageLink, courseId, onEnroll, onUserClearMessage } = props;
  
    const user = JSON.parse(localStorage.getItem("user"));
  
    const { enqueueSnackbar } = useSnackbar();
  
    useEffect(() => {
      if (success) {
        enqueueSnackbar(success, { variant: "success" });
        onUserClearMessage();
      }
      if (error) {
        enqueueSnackbar(error, { variant: "error" });
        onUserClearMessage();
      }
    }, [error, success, enqueueSnackbar, onUserClearMessage]);
  
    const infoList = [
      { icon: <FindInPageIcon fontSize="small" />, text: "1 article" },
      {
        icon: <AllInclusiveIcon fontSize="small" />,
        text: "Full lifetime accesso",
      },
      {
        icon: <PhoneIphoneIcon fontSize="small" />,
        text: "Access on mobile and TV",
      },
      {
        icon: <BuildIcon fontSize="small" />,
        text: "SkillsFuture Credit eligible",
      },
      {
        icon: <VerifiedUserIcon fontSize="small" />,
        text: "Certificate of Completion",
      },
    ];

    if(!book) return(<h1>Loading ...</h1>)
  
    return (
      <Card className={cx(cardStyles.root_chap, shadowStyles.root_chap)}>
        {/* {imageLink ? (
          <CardMedia classes={mediaStyles} image={imageLink} />
        ) : (
          <Skeleton variant="rect" width={"100%"} height={150} />
        )}
        <Avatar className={cardStyles.avatar} src={"https://i.pravatar.cc/300"} /> */}
  
  <Box mx={2}>
            <Box
              component={Link}
              to={`/course/${courseDetail.course.id}`}
              style={{ textDecoration: "none" }}
            >
              <Button size="small" className={cardStyles.button}>
                Back to Course
              </Button>
            </Box>
          </Box>

        <Box mt={2}>
          <Typography align="center" variant="h5" gutterBottom>
          {book.info.title}
          </Typography>
        </Box>
  

        
  
        <Box mt={2}>
          {/* <Box ml={2}>
            <Typography variant="subtitle1">This course includes</Typography>
          </Box> */}
          <List>
          {book?.info?.chap.map((text, index) => (
            <ListItem button key={index} onClick={() => changeContent(index)}>
              <ListItemText className={classes.list} primary={text} />
            </ListItem>
          ))}
        </List>
          {/* <List disablePadding dense>
            {infoList.map((info, index) => (
              <ListItem key={index}>
                <ListItemIcon style={{ minWidth: 32 }}>{info.icon}</ListItemIcon>
                <ListItemText secondary={info.text} />
              </ListItem>
            ))}
          </List> */}
        </Box>
      </Card>
    );
  };

  return (
    <Grid container direction={matchMD ? "row" : "column-reverse"}>
      <Grid item xs={matchMD ? 8 : 12}>
        <Box py={onShow ? 5 : 0}>
          <Grid container alignItems="center" style={{ minHeight: 350 }}>
            <Box mx={7} width={"100%"}>

            {book?.chap[content]?.content && (
          <InnerHTML html={book.chap[content].content} />
        )}



              <Typography variant="h4" gutterBottom>
                {/* {book && <h1>{book.info.title}</h1>} */}
                {/* {loading ? (
                  <Skeleton variant="text" width={"100%"} />
                ) : (
                  courseDetail.title
                )} */}
              </Typography>
              <Typography gutterBottom>
                {/* {loading ? (
                  <Skeleton variant="text" width={"100%"} />
                ) : (
                  courseDetail.desc
                )} */}
              </Typography>
              {/* <HoverRating /> */}

              {/* <Box>
                {loading ? (
                  <Skeleton variant="text" width={"50%"} />
                ) : (
                  <Grid container alignItems="center">
                    <Box mr={3}>Created by {nguoiTao}</Box>
                    <Box>Last updated {courseDetail.ngayTao}</Box>
                  </Grid>
                )}
              </Box> */}

              {/* {myInfo ? (
                <Box my={2}>
                  {loading ? (
                    <Skeleton variant="text" width={"60%"} />
                  ) : (
                    <Grid container alignItems="center">
                      <Box display="flex" flexDirection="column" ml={1}>
                        <Box display="flex" alignItems="center">
                          <PersonAddIcon
                            fontSize="small"
                            style={{ margin: "0 10" }}
                          />
                          {userList && userList.lstHocVien
                            ? userList.lstHocVien.length
                            : "0"}
                        </Box>
                        <Typography>Enerolled</Typography>
                      </Box>
                      {userListRender}
                      <Tooltip title="Show All">
                        <Switch
                          classes={switchStyles}
                          checked={onShow}
                          onChange={(e) => setOnShow(e.target.checked)}
                        />
                      </Tooltip>
                    </Grid>
                  )}
                </Box>
              ) : null} */}

              {/* {showAllUserRender} */}
            </Box>
          </Grid>
        </Box>
        
      </Grid>

      <Grid item xs={matchMD ? 4 : 12}>
        <Box mx={2} className={classes.position}>
          <ShowcaseCard
            isMe={isMe}
            loading={loading}
            // imageLink={courseDetail.hinhAnh}
            imageLink={"courseDetail.hinhAnh"}
            courseId={match.params.id}
          />
        </Box>
      </Grid>
    </Grid>
  );
}

const mapStateToProps = (state) => {
  return {
    courseDetail: state.courses.courseDetail,
    loading: state.courses.loading,
    userList: state.user.userList,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onFetchCourseDetail: (courseId) =>
      dispatch(actions.fetchCourseDetail(courseId)),
    onFetchUserListInThisCourse: (courseId) =>
      dispatch(actions.fetchUserListInThisCourse(courseId)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(CourseDetail));
