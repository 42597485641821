import React, { useEffect } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { connect } from "react-redux";
import * as actions from "./store/actions";

import UserLayout from "./hoc/Layout/UserLayout";

import Dashboard from "./containers/Dashboard/Dashboard";
import Courses from "./containers/Courses/Courses";
import Products from "./containers/Products/Products"; //ProductListing";
import MyCourses from "./containers/Courses/MyCourses"; 
import MyProfile from "./containers/Profile/Profile"; 
import MyApps from "./containers/Products/MyApps";
import Auth from "./containers/Auth/Auth";
import CourseDetail from "./components/CourseList/CourseDetail/CourseDetail";
import CoursePage from "./components/CourseList/CoursePage/CoursePage";
import ProductDetail from "./components/ProductList/CourseDetail/CourseDetail";
import Logout from "./containers/Auth/Logout/Logout";
import UsersManager from "./containers/UsersManager/UsersManager";
import CoursesManager from "./containers/CoursesManager/CoursesManager";
import PageNotFound from "./containers/PageNotFound/PageNotFound";
// import About from "./containers/Auth/About/About";
import Companies from "./containers/Apps/Apps";
import Sbooks from "./containers/Sbooks/Sbooks";
import Tour from "./containers/Help/Help";
import BookPage from "./components/CourseList/CoursePage/BookPage/BookPage";
import VideoPage from "./components/CourseList/CoursePage/VideoPage/VideoPage";

// import ProductDetail from "./containers/Products/ProductDetail";

const RouteUser = ({ Component, ...props }) => {
  return (
    <Route
      {...props}
      render={() => (
        <UserLayout>
          <Component />
        </UserLayout>
      )}
    />
  );
};

const RouteAdmin = ({ Component, isAdmin, ...props }) => {
  return (
    <Route
      {...props}
      render={() =>
        isAdmin ? (
          <UserLayout>
            <Component />
          </UserLayout>
        ) : (
          <PageNotFound />
        )
      }
    />
  );
};

const App = ({ onTryAutoSignup }) => {
  const user = JSON.parse(localStorage.getItem("user"));
  // const isAdmin = user && user.maLoaiNguoiDung === "GV";
  //My changes
  const isAdmin = true;

  useEffect(() => {
    onTryAutoSignup();
  }, [onTryAutoSignup]);

  return (
    <BrowserRouter basename={process.env.PUBLIC_URL}>
      <Switch>
        {/* With Layout */}
        <RouteUser path="/" exact Component={Dashboard} />
        <RouteUser path="/help" exact Component={Tour} />
        <RouteUser path="/courses" exact Component={Courses} />
        <RouteUser path="/vlab" exact Component={Products} />
        <RouteUser path="/my-courses" exact Component={MyCourses} />
        <RouteUser path="/my-profile" exact Component={MyProfile} />
        <RouteUser path="/my-apps" exact Component={MyApps} />
        <RouteUser path="/courses/:id" Component={CourseDetail} />
        <RouteUser path="/course/:id" Component={CoursePage} />
        <RouteUser path="/vlab/:id" Component={ProductDetail} />
        <RouteUser path="/logout" Component={Logout} />
        <RouteUser path="/apps" exact Component={Companies} />
        <RouteUser path="/sbooks" exact Component={Sbooks} />
        <RouteUser path="/book/:id" Component={BookPage} />
        <RouteUser path="/video/:id" Component={VideoPage} />
        {/* <Route path="/product/:productId" exact component={ProductDetail} /> */}
        <RouteAdmin
          path="/users-management"
          Component={UsersManager}
          isAdmin={isAdmin}
        />
        <RouteAdmin
          path="/courses-management"
          Component={CoursesManager}
          isAdmin={isAdmin}
        />
        {/* Without Layout */}
        <Route path="/sign-in" component={Auth} />
        <Route path="/sign-up" component={Auth} />
        {/* Page Not Found */}
        <Route component={PageNotFound} />
      </Switch>
    </BrowserRouter>
  );
};

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.auth.token !== null,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onTryAutoSignup: () => dispatch(actions.authCheckState()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
