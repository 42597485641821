import React from "react";
import Switch from "@material-ui/core/Switch";
import { Tooltip } from "@material-ui/core";
// import { useN01SwitchStyles } from "@mui-treasury/styles/switch/n01";
import { useN02SwitchStyles } from '@mui-treasury/styles/switch/n02';
// import { withStyles } from '@material-ui/core/styles';
import { connect } from "react-redux";
import * as actions from "../../store/actions";


// const AntSwitch = withStyles((theme) => ({
//   root: {
//     width: 28,
//     height: 16,
//     padding: 0,
//     display: 'flex',
//   },
//   switchBase: {
//     padding: 2,
//     color: theme.palette.grey[500],
//     '&$checked': {
//       transform: 'translateX(12px)',
//       color: theme.palette.common.white,
//       '& + $track': {
//         opacity: 1,
//         backgroundColor: theme.palette.primary.main,
//         borderColor: theme.palette.primary.main,
//       },
//     },
//   },
//   thumb: {
//     width: 12,
//     height: 12,
//     boxShadow: 'none',
//   },
//   track: {
//     border: `1px solid ${theme.palette.grey[500]}`,
//     borderRadius: 16 / 2,
//     opacity: 1,
//     backgroundColor: theme.palette.common.white,
//   },
//   checked: {},
// }))(Switch);


const LangSwitch = ({ lang, onChnageLang }) => {
  const switchStyles = useN02SwitchStyles();
  const localTheme = JSON.parse(localStorage.getItem("lang"));

  let isTheme = lang;
  if (!lang) {
    isTheme = localTheme;
  }

  return (
    <div>
      {/* ENG */}
      <Tooltip title="Language">
        <Switch
          classes={switchStyles}
          checked={isTheme}
          onChange={(e) => onChnageLang(e.target.checked)}
        />

{/* <AntSwitch checked={isTheme} onChange={(e) => onChnageLang(e.target.checked)} /> */}
        
      </Tooltip>
      {/* PER */}
      {/* <AntSwitch checked={isTheme} onChange={(e) => onChnageLang(e.target.checked)} />  */}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    lang: state.ui.lang,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onChnageLang: (lang) => dispatch(actions.changeLanguage(lang)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LangSwitch);
