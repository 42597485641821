import React, { Component } from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import { Link } from "react-router-dom";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Typography from "@material-ui/core/Typography";

const styles = {
  card: {
    position: "relative",
    display: "flex",
    marginBottom: 20,
  },
  image: {
    minWidth: 200,
  },
  content: {
    padding: 25,
    objectFit: "cover",
  },
};

class Scream extends Component {
  render() {
    const {
      classes,
      scream: { titleP, descP, image, link },
    } = this.props;

    return (
      <a href={link} target="_blank" style={{ textDecoration: "none" }}>
        <Card className={classes.card}>
          <CardMedia
            image={image}
            title="Profile image"
            className={classes.image}
          />

          <CardContent className={classes.content}>
            <Typography
              variant="h5"
              // component={Link}
              color="primary"
            >
              {titleP}
            </Typography>

            {/* {deleteButton} */}
            <Typography variant="body2" color="textSecondary">
              {/* {dayjs(createdAt).fromNow()} */}
            </Typography>
            <Typography variant="body1">{descP}</Typography>
            {/* <LikeButton screamId={id} /> */}
            {/* <span>{id} Likes</span> */}
            {/* <MyButton tip="comments">
            <ChatIcon color="primary" />
          </MyButton> */}
            {/* <span>{sponsor} comments</span> */}
            {/* <ScreamDialog
            screamId={id}
            userHandle={title}
            openDialog={this.props.openDialog}
          /> */}
          </CardContent>
        </Card>
      </a>
    );
  }
}

export default withStyles(styles)(Scream);
