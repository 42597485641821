import React, { Fragment, useState } from "react";

import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";

import { useMediaQuery, Avatar } from "@material-ui/core";
import { GridList, GridListTile } from "@material-ui/core";
import { Grid, Button, Box, Typography, Paper } from "@material-ui/core";

import { LiveTv, AllInclusive, Bookmark } from "@material-ui/icons";

import Carousel from "react-material-ui-carousel";
import Image from "material-ui-image";
import CountUp from "react-countup";

import { AutoRotatingCarousel, Slide } from "material-auto-rotating-carousel";

import CourseListP from "../../components/CourseList/CourseListP";

import heroImage from "../../assets/images/home-hero.jpg";

import course1 from "../../assets/images/episodes/1.png";
import course2 from "../../assets/images/episodes/2.png";
import course3 from "../../assets/images/episodes/3.png";
import course4 from "../../assets/images/episodes/4.png";
import course5 from "../../assets/images/episodes/5.png";
import course6 from "../../assets/images/episodes/6.png";
import course7 from "../../assets/images/episodes/7.png";

import tileimage1 from "../../assets/images/blog/img-1.jpg";
import tileimage2 from "../../assets/images/blog/img-2.jpg";
import tileimage3 from "../../assets/images/blog/img-3.jpg";
import tileimage4 from "../../assets/images/blog/img-4.jpg";
import tileimage5 from "../../assets/images/blog/img-5.gif";

import './index.css';

const useStyles = makeStyles((theme) => ({
  heroText: {
    position: "absolute",
    margin: "0 10% 0 10%",
    color: "white",
    //my changes
    direction: "rtl",
  },
  header: {
    height: "60vh",
    backgroundSize: "cover",
    backgroundPosition: "65% 50%",
    backgroundAttachment: "fixed",
    backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url(${heroImage})`,
  },
  avatar: {
    backgroundColor: "#e67e22",
  },
  feature: {
    color: "white",
    minHeight: "30vh",
    position: "relative",
    background: `linear-gradient(120deg, #2980b9, #8e44ad)`,
  },
  intro: {
    position: "relative",
    background: `linear-gradient(120deg, #2980b9, #8e44ad)`,
    animation: `5s ease 0s infinite normal none running Gradient`,
    color: "white",
  },
  topSwoop: {
    position: "absolute",
    top: "-2px",
  },
  bottomSwoop: {
    position: "absolute",
    bottom: "-2px",
    zIndex: 0,
  },
  gridListRoot: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-around",
    overflow: "hidden",
    backgroundColor: theme.palette.background.paper,
  },
  gridList: {
    flexWrap: "wrap",
    transform: "translateZ(0)",
  },
  titleNumber: {
    lineHeight: "85%",
    "@media (max-width: 1274px)": {
      lineHeight: "100%",
    },
    "@media (max-width: 600px)": {
      fontSize: "4rem",
    },
  },
}));

const slideItems = [
  {
    media: course1,
    title: "Modern Phyics",
    subtitle: "Optics, Lasers, Nanophysics, Quantum physics, Applied physics, Astrophysics, Renewable Energy.",
  },
  {
    media: course2,
    title: "Information technology",
    subtitle: "Hardware and software, Web development, Html5, CSS, Javascript, WordPress, Mobile Applications.",
  },
  {
    media: course3,
    title: "Electronics & Robotics",
    subtitle:
      "Analog, Digital, Microcontroller, Robot building and programming, Lego Mindstorm, Arduino, Arm, Fpga, PLC.",
  },
  {
    media: course4,
    title: "Arts & Technology",
    subtitle:
      "Software, Tools, Digital Photography, Music, Design software.",
  },
  {
    media: course5,
    title: "Artificial Intelligence",
    subtitle:
      "Artificial neural networks, Deep learning, Reactive Machines, Theory of Mind, Self-Awareness.",
  },
  {
    media: course6,
    title: "Multidisciplinary Sciences and Techs",
    subtitle:
      "Nanosiceince and Nanotechnology, Biophysics, Photonics, Biomedical engineering.",
  },
  {
    media: course7,
    title: "Unmanned aerial vehicle",
    subtitle:
      "Drones, Multirotors, Simulators, Sensors, Software.",
  },
];

const tileData = [
  {
    img: tileimage1,
    cols: 1,
  },
  {
    img: tileimage2,
    cols: 1,
  },
  {
    img: tileimage3,
    cols: 1,
  },
  {
    img: tileimage4,
    cols: 1,
  },
  {
    img: tileimage5,
    cols: 2,
  },
];

const featureList = [
  {
    icon: <LiveTv />,
    title: "دوره آنلاین" ,
    subtitle: "از شاخه‌های گوناگون آموزشی بهره‌مند شوید",
    count: <CountUp end={100} duration={6} style={{ marginRight: 4 }} />,
  },
  {
    icon: <Bookmark />,
    title: "استادان و آموزش‌یاران با تجربه",
    subtitle: "استاد مناسب خود را برگزینید",
    count: null,
  },
  {
    icon: <AllInclusive />,
    title: "دسترسی همیشگی",
    subtitle: "مطابق با برنامه‌ریزی شخصی آموزش ببینید",
    count: null,
  },
];

const AutoRotatingCarouselModal = ({ handleOpen, setHandleOpen, isMobile }) => {
  return (
    <div>
      <AutoRotatingCarousel
        label="Get started"
        open={handleOpen.open}
        onClose={() => setHandleOpen({ open: false })}
        onStart={() => setHandleOpen({ open: false })}
        autoplay={false}
        hideArrows={false}
        mobile={isMobile}
      >
        {slideItems.map((item) => (
          <Slide
            key={item.title}
            media={<img src={item.media} alt={item.title} />}
            title={item.title}
            subtitle={item.subtitle}
            mediaBackgroundStyle={{
              background: `linear-gradient(120deg, #2980b9, #8e44ad)`,
            }}
            style={{ background: `linear-gradient(120deg, #2980b9, #8e44ad)` }}
          />
        ))}
      </AutoRotatingCarousel>
    </div>
  );
};

function Dashboard({ darkTheme }) {
  const classes = useStyles();
  const matchSM = useMediaQuery("(min-width:600px)");
  const matchMD = useMediaQuery("(min-width:1000px)");
  const matchLG = useMediaQuery("(min-width:1400px)");
  const user = JSON.parse(localStorage.getItem("user"));
  const localTheme = JSON.parse(localStorage.getItem("darkTheme"));
  const [handleOpen, setHandleOpen] = useState({ open: false });

  let isTheme = darkTheme;
  if (!darkTheme) {
    isTheme = localTheme;
  }

  const handleClick = () => {
    setHandleOpen({ open: true });
  };

  const topSwoop = (
    <svg
      viewBox="0 0 1430 140"
      className={classes.topSwoop}
      fill={isTheme ? "#303030" : "#fafafa"}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M1440 0v59.969c-65.287-39.594-188.865-55.343-370.736-47.248C766 26.221 627.87 140 277 140 171.698 140 79.365 124.417 0 93.25V0h1440z"></path>
    </svg>
  );

  const bottomSwoop = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 1430 140"
      fill={isTheme ? "#303030" : "#fafafa"}
      className={classes.bottomSwoop}
    >
      <path d="M0 140h1440V46.75C1360.635 15.583 1268.302 0 1163 0 812.13 0 674 113.78 370.736 127.279 188.866 135.374 65.286 119.625 0 80.03V140z"></path>
    </svg>
  );

  return (
    <Fragment>
      <Grid container alignItems="center" className={classes.header}>
        <Grid item className={classes.heroText}>
          <Typography className="main" variant="h4" gutterBottom>
          فراگیری دانش، فناوری، هنر و ...
          </Typography>
          <Typography className="main" variant="subtitle1" gutterBottom>
            آموزش هوشمند: دوره‌های آموزشی دربرگیرنده شبیه‌سازهای تعاملی، آزمایشگاه‌های مجازی، کتاب‌های هوشمند  
          </Typography>
          <Button className="main" variant="contained" color="primary" onClick={handleClick}>
            راهنما
          </Button>
          <AutoRotatingCarouselModal
            isMobile={matchSM}
            handleOpen={handleOpen}
            setHandleOpen={setHandleOpen}
          />
        </Grid>
      </Grid>

      <Box pb={7} className={classes.feature}>
        <Grid container justify="space-between" alignItems="center">
          {featureList.map((item) => (
            <Box m={2} display="flex" alignItems="center" key={item.title}>
              <Avatar className={classes.avatar}>{item.icon}</Avatar>
              <Box ml={1} display="flex" flexDirection="column">
                <Box display="flex" alignItems="center">
                  
                  <Typography variant="subtitle1">{item.title}</Typography>
                  &nbsp;
                  <div className="main">
                  {item.count ? item.count : null}
                  </div>
                  {/* <Typography variant="subtitle1">بیش از</Typography> */}
                </Box>
                <Typography variant="caption">{item.subtitle}</Typography>
              </Box>
            </Box>
          ))}
        </Grid>
        <Box>{bottomSwoop}</Box>
      </Box>

      <Box my={5} style={{ minHeight: 520 }}>
        <Box mx={6} py={3}>
          <Typography className="main" variant="h5" gutterBottom>
            <strong>گنجینه‌ای از دوره‌های آموزشی هوشمند و تعاملی</strong>
          </Typography>
          <Typography className="main" variant="subtitle1" gutterBottom>
            موضوع دلخواه خود را از بین دوره‌های آموزشی گوناگون برگزینید 
          </Typography>
        </Box>
        <CourseListP/>
      </Box>

      <Box className={classes.intro}>
        {topSwoop}
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          minHeight="90vh"
        >
          <Box  mx={5} minWidth={315} alignSelf="center">
            <Box >
              <Typography className="main" 
              variant="h4" color="inherit">
                دوره آموزشی خود را گزینش و ثبت نام کنید
              </Typography>
            </Box>
            <Box mt={3}>
              <Typography className="main">
                برای دسترسی به محتوای هر دوره آموزشی در دوره مورد نظر ثبت نام کنید
              </Typography>
            </Box>
            {user ? null : (
              <Box mt={2}>
                <Button
                  variant="contained"
                  color="primary"
                  component={Link}
                  to={"/sign-up"}
                  style={{ width: 150 }}
                >
                  Sign up
                </Button>
                <Button
                  variant="outlined"
                  component={Link}
                  to={"/sign-in"}
                  style={{ width: 150, marginLeft: 8, color: "inherit" }}
                >
                  Log in
                </Button>
              </Box>
            )}
          </Box>
        </Box>
        {bottomSwoop}
      </Box>

      <Box my={5} display="flex" alignContent="center" justifyContent="center">
        <Box width="100vh">
          <Carousel
            animation={"slide"}
            timeout={500}
            indicators={false}
            className={classes.carousel}
          >
            {slideItems.map((item) => (
              <Paper key={item.title}>
                <Image src={item.media} aspectRatio={16 / 9} />
              </Paper>
            ))}
          </Carousel>
        </Box>
      </Box>

      <Box className={classes.intro}>
        {topSwoop}
        <Box
          display="flex"
          flexDirection="column"
          flexWrap="nowrap"
          pt={matchLG ? 20 : matchMD ? 15 : 10}
          pb={5}
        >
          <Box alignSelf="flex-start" maxWidth={500} m={5}>
            <Box display="flex">
              <Typography  variant="h1" className={classes.titleNumber}>
                1
              </Typography>
              <Box ml={1} display="flex" flexDirection="column">
                <Typography className="main"  variant="h4">
                  ویدئوهای دوسویه
                </Typography>
                <Typography className="main"  style={{ marginTop: 16 }}>
                  با ویدئوهای دوسویه آموزشگاه و آموزش‌یار را به خانه ببرید
                </Typography>
              </Box>
            </Box>
          </Box>

          <Box alignSelf="flex-end" maxWidth={500} m={5}>
            <Box display="flex">
              <Typography variant="h1" className={classes.titleNumber}>
                2
              </Typography>
              <Box ml={1} display="flex" flexDirection="column">
                <Typography className="main"  variant="h4">
                  کتاب‌های هوشمند
                </Typography>
                <Typography className="main" style={{ marginTop: 16 }}>
                  فراگیری را با محتوای چندرسانه‌ای، هوشمند و تعاملی همراه با سرگرمی و لذت تجربه کنید
                </Typography>
              </Box>
            </Box>
          </Box>

          <Box alignSelf="flex-start" maxWidth={500} m={5}>
            <Box display="flex">
              <Typography variant="h1" className={classes.titleNumber}>
                3
              </Typography>
              <Box ml={1} display="flex" flexDirection="column">
                <Typography className="main"  variant="h4">
                  شبیه‌ساز و آزمایشگاه‌های مجازی
                </Typography>
                <Typography className="main" style={{ marginTop: 16 }}>
                  مطالب پیچیده علمی را به صورت عملی و با بهره‌گیری از انواع شبیه‌ساز و آزمایشگاه مجازی مسلط شوید 
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
        {bottomSwoop}
      </Box>

      <Box my={5} display="flex" alignContent="center" justifyContent="center">
        <Box width="100vh">
          <GridList cellHeight={160} className={classes.gridList} cols={3}>
            {tileData.map((tile) => (
              <GridListTile key={tile.img} cols={tile.cols || 1}>
                <img src={tile.img} alt={tile.title} />
              </GridListTile>
            ))}
          </GridList>
        </Box>
      </Box>

      <Box className={classes.intro}>
        {topSwoop}
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          minHeight="50vh"
        >
          <Box mx={5} mt={5} minWidth={315} alignSelf="center">
            <Box>
              <Typography className="main"  variant="h4" color="inherit">
                دنیای دانش و فناوری چشم به راه شماست
              </Typography>
            </Box>
            <Box mt={3}>
              <Typography className="main" >هم اکنون به دانشپارک بپیوندید و از آموزش هوشمند بهره‌مند شوید</Typography>
            </Box>
            {user ? null : (
              <Box mt={2}>
                <Button
                  variant="contained"
                  size="large"
                  color="primary"
                  component={Link}
                  to={"/signup"}
                  style={{ width: 150 }}
                >
                  Sign up
                </Button>
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    </Fragment>
  );
}

const mapStateToProps = (state) => {
  return {
    darkTheme: state.ui.darkTheme,
    lang: state.ui.lang,
  };
};

export default connect(mapStateToProps)(Dashboard);
