import React, { useEffect, useState } from "react";
import { Box, Grid, Typography } from "@material-ui/core";
import { connect } from "react-redux";

import Spinner from "../../components/UI/Spinner/Spinner";
import AppCard from "./AppCardE";
import AppCardP from "./AppCardP";
import axios from "../../axios";

const Apps = ({ darkTheme, lang }) => {
  const localTheme = JSON.parse(localStorage.getItem("darkTheme"));
  const localLang = JSON.parse(localStorage.getItem("lang"));

  const [loading, setLoading] = useState(true);

  const [appList, setAppList] = useState(null);

  let isTheme = darkTheme;
  let isEng = lang;

  if (!lang) {
    isEng = localLang;
  }

  if (!darkTheme) {
    isTheme = localTheme;
  }

  const getAppsList = () => {
    axios.get(`/site/apps`).then((response) => {
      setAppList(response.data.apps);
      setLoading(false);
    });
  };

  useEffect(() => {
    getAppsList();
  }, []);

  let courseListRender = <Spinner />;
  if (!loading && appList.length > 0) {
    courseListRender = appList.map((course, index) => (
      <Grid item key={index}>
        {isEng ? (
          <AppCardP key={index} scream={course} />
        ) : (
          <AppCard key={index} scream={course} />
        )}
      </Grid>
    ));
  }

  const AppsE = () => {
    return (
      <>
        <Box my={5} style={{ minHeight: 520 }}>
          <Box mx={6} py={3}>
            <Typography variant="h5" gutterBottom>
              <strong>Educational Applications</strong>
            </Typography>
            <Typography variant="subtitle1" gutterBottom>
              Games, Entertainments, Magazines, ...
            </Typography>
          </Box>
          <Grid container spacing={2} justify="center">
            {courseListRender}
          </Grid>
        </Box>
      </>
    );
  };

  const AppsP = () => {
    return (
      <>
        <Box my={5} style={{ minHeight: 520 }}>
          <Box mx={6} py={3}>
            <Typography variant="h5" gutterBottom>
              <strong>اپلیکیشن‌های آموزشی</strong>
            </Typography>
            <Typography variant="subtitle1" gutterBottom>
              بازی، سرگرمی، مجله، ...
            </Typography>
          </Box>
          <Grid container spacing={2} justify="center">
            {courseListRender}
          </Grid>
        </Box>
      </>
    );
  };

  return <Box>{isEng ? <AppsP /> : <AppsE />}</Box>;
};

const mapStateToProps = (state) => {
  return {
    darkTheme: state.ui.darkTheme,
    lang: state.ui.lang,
  };
};

export default connect(mapStateToProps)(Apps);
