import React, { useEffect, useState } from "react";
import { Box, Typography } from "@material-ui/core";
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import Controls from "./controls/Controls";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiFormControl-root": {
      width: "80%",
      margin: theme.spacing(1),
    },
  },
}));

const initVals = {
  physics: "5",
  math: "5",
  it: "5",
  electronics: "5",
  robotics: "5",
  nano: "5",
  aerospaces: "5",
  astrophys: "5",
  bio: "5",
  arts: "5",
  lang: "5",
};

const qItems = [
  { id: "0", title: "0" },
  { id: "1", title: "1" },
  { id: "2", title: "2" },
  { id: "3", title: "3" },
  { id: "4", title: "4" },
  { id: "5", title: "No idea" },
];
// import axios from "../../../../axios";

const FormP = () => {
  const classes = useStyles();
  const [values, setValues] = useState(initVals);
  const [errors, setErrors] = useState({});
  // const { children, ...other } = props;

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
    // if (validateOnChange)
    //     validate({ [name]: value })
  };

  const resetForm = () => {
    setValues(initVals);
    setErrors({});
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Values", values);
    // if (validate()){
    //     employeeService.insertEmployee(values)
    //     resetForm()
    // }
  };

  return (
    <form className={classes.root} autoComplete="off" onSubmit={handleSubmit}>
      <Grid container>
        <Grid item xs={12} md={6}>
          <Controls.RadioGroup
            name="physics"
            label="Physics"
            value={values.physics}
            onChange={handleInputChange}
            items={qItems}
          />
          <Controls.RadioGroup
            name="math"
            label="Mathematics"
            value={values.math}
            onChange={handleInputChange}
            items={qItems}
          />
          <Controls.RadioGroup
            name="it"
            label="Information Technology"
            value={values.it}
            onChange={handleInputChange}
            items={qItems}
          />
          <Controls.RadioGroup
            name="electronics"
            label="Electronics"
            value={values.electronics}
            onChange={handleInputChange}
            items={qItems}
          />
          <Controls.RadioGroup
            name="robotics"
            label="Robotics"
            value={values.robotics}
            onChange={handleInputChange}
            items={qItems}
          />
          <Controls.RadioGroup
            name="nano"
            label="Nanotechnology"
            value={values.nano}
            onChange={handleInputChange}
            items={qItems}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <Controls.RadioGroup
            name="aerospaces"
            label="Aerospaces"
            value={values.aerospaces}
            onChange={handleInputChange}
            items={qItems}
          />
          <Controls.RadioGroup
            name="astrophys"
            label="Astrophysics"
            value={values.astrophys}
            onChange={handleInputChange}
            items={qItems}
          />
          <Controls.RadioGroup
            name="bio"
            label="Biology"
            value={values.bio}
            onChange={handleInputChange}
            items={qItems}
          />
          <Controls.RadioGroup
            name="arts"
            label="Arts"
            value={values.arts}
            onChange={handleInputChange}
            items={qItems}
          />
          <Controls.RadioGroup
            name="lang"
            label="Language and Literature"
            value={values.lang}
            onChange={handleInputChange}
            items={qItems}
          />
          <div>
            <Controls.Button type="submit" text="Submit" />
            <Controls.Button text="Reset" color="default" onClick={resetForm} />
          </div>
        </Grid>
      </Grid>
    </form>
  );
};

export default FormP;
