import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { makeStyles, useTheme } from "@material-ui/core/styles";

import { Button, Box, Grid, Typography, useMediaQuery } from "@material-ui/core";
// import { Avatar, Tooltip, Chip } from "@material-ui/core";
// import AvatarGroup from "@material-ui/lab/AvatarGroup";

// import Switch from "@material-ui/core/Switch";
import { useN01SwitchStyles } from "@mui-treasury/styles/switch/n01";

// import PersonAddIcon from "@material-ui/icons/PersonAdd";

// import HoverRating from "../../Rating/Rating";
// import CourseTabs from "./CourseTabs/CourseTabs";
// import ShowcaseCard from "../CourseCard/ShowcaseCard";

import * as actions from "../../../store/actions";
// import Skeleton from "@material-ui/lab/Skeleton";

const useStyles = makeStyles((theme) => ({
  position: {
    marginTop: "5%",
    [theme.breakpoints.up("md")]: {
      position: "fixed",
      marginTop: "8%",
      marginLeft: "5%",
    },
  },
  chipBox: {
    display: "flex",
    flexWrap: "wrap",
    "& > *": {
      margin: theme.spacing(0.5),
    },
  },
  button: {
    display: "block",
    width: "100%",
    height: "3rem",
    border: "none",
    background: "linear-gradient(120deg, #2980b9, #8e44ad, #2980b9)",
    backgroundSize: "200%",
    color: "#fff",
    outline: "none",
    transition: "0.5s",
    cursor: "pointer",

    "&:hover": {
      backgroundPosition: "right",
    },
  },
}));

function CourseDetail(props) {
  const classes = useStyles();
  const theme = useTheme();
  const matchMD = useMediaQuery(theme.breakpoints.up("md"));
  const myInfo = JSON.parse(localStorage.getItem("user"));
  const {productId, productDetail, loading, match, userList } = props;
  const { onFetchProductDetail, onEnrollProduct, onFetchUserListInThisProduct } = props;

  const switchStyles = useN01SwitchStyles();
  const [onShow, setOnShow] = useState(false);


  // console.log("product:", productDetail)
  const { image, title, price, category, description } = productDetail;



  // let nguoiTao;
  // if (productDetail.nguoiTao) {
  //   nguoiTao = productDetail.nguoiTao.hoTen;
  // }

  useEffect(() => {
    onFetchProductDetail(match.params.id);
  }, [match.params.id, onFetchProductDetail]);

  // useEffect(() => {
  //   onFetchUserListInThisProduct(match.params.id);
  // }, [match.params.id, onFetchUserListInThisProduct]);

  let nameList = [];
  let userListRender;


  let showAllUserRender;

  let isMe = false;

  return (
    <Grid container direction={matchMD ? "row" : "column-reverse"}>
      <Grid item xs={matchMD ? 12 : 12}>
        {/* <Box py={onShow ? 5 : 0}>
          <Grid container alignItems="center" style={{ minHeight: 350 }}>
            <Box mx={7} width={"100%"}>
              <Typography variant="h4" gutterBottom>
                {loading ? (
                  <Skeleton variant="text" width={"100%"} />
                ) : (
                  productDetail.title
                )}
              </Typography>
              <Typography gutterBottom>
                {loading ? (
                  <Skeleton variant="text" width={"100%"} />
                ) : (
                  productDetail.desc
                )}
              </Typography>


            </Box>
          </Grid>
        </Box> */}
     
        <div 
        dangerouslySetInnerHTML={{
          __html: `
      <style>.h_iframe-aparat_embed_frame{position:relative;}.h_iframe-aparat_embed_frame .ratio{display:block;width:100%;height:auto;}.h_iframe-aparat_embed_frame iframe{position:absolute;top:0;left:0;width:100%;height:100%;}</style><div class="h_iframe-aparat_embed_frame"><span style="display: block;padding-top: 57%"></span><iframe src="${productDetail.simLink}" allowFullScreen="true" webkitallowfullscreen="true" mozallowfullscreen="true"></iframe></div>
      `,
        }}
      ></div>
      <a
        href={productDetail.simLink}
        target="_blank"
        style={{ textDecoration: "none"}}
      >
        {/* در پنجره جدید باز کن */}
        Open in a new window
      </a>



      </Grid>

      {/* <Grid item xs={matchMD ? 4 : 12}>
        <Box mx={2} className={classes.position}>
          <ShowcaseCard
            isMe={isMe}
            loading={loading}
            imageLink={productDetail.hinhAnh}
            productId={match.params.id}
          />
        </Box>
      </Grid> */}
    </Grid>
  );
}

// const mapStateToProps = (state) => {
//   return {
//     courseDetail: state.courses.courseDetail,
//     loading: state.courses.loading,
//     userList: state.user.userList,
//   };
// };

// const mapDispatchToProps = (dispatch) => {
//   return {
//     onFetchCourseDetail: (productId) =>
//       dispatch(actions.fetchCourseDetail(productId)),
//     onFetchUserListInThisCourse: (productId) =>
//       dispatch(actions.fetchUserListInThisCourse(productId)),
//   };
// };
const mapStateToProps = (state) => {
  return {
    // courseDetail: state.courses.courseDetail,
    // loading: state.products.loading,
    // userList: state.user.userList,

    productDetail: state.product,
    loading: state.products.loading,
    userList: state.user.userList,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    // onFetchCourseDetail: (productId) =>
    //   dispatch(actions.fetchCourseDetail(productId)),
    // onFetchUserListInThisCourse: (productId) =>
    //   dispatch(actions.fetchUserListInThisCourse(productId)),

      onFetchProductDetail: (productId) =>
      dispatch(actions.fetchProduct(productId)),
    // onFetchUserListInThisProduct: (productId) =>
    //   dispatch(actions.fetchUserListInThisProduct(productId)),
    onEnrollProduct: (productId, isMe) =>
    dispatch(actions.EnrollProduct(productId, isMe)),

  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(CourseDetail));
